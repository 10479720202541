import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import BrandImage from "@/assets/img/brand-specialist.png";
import ImageBank from "@/assets/img/image-bank.png";
import SilboAdCreator from "@/assets/img/silbo-creator.png";

import CardApp from "@/components/CardApp/CardApp";

import { setProfile } from "@/redux/store";

import { SILBO_PROFILES } from "@/utils";

const SilboHomeContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Row className="row-container">
      <Col xs={4}>
        <CardApp
          auxiliaryText="Tu asistente experto en Marca Silbö"
          available={true}
          image={BrandImage}
          isForText={true}
          onClick={() => {
            dispatch(setProfile(SILBO_PROFILES.SILBO_BRAND_SPECIALIST));
            navigate(SILBO_PROFILES.SILBO_BRAND_SPECIALIST, { replace: true });
          }}
          text="Silbö Copywriter"
          textForBlur="30”"
        />
      </Col>
      <Col xs={4}>
        <CardApp
          auxiliaryText="Genera imágenes Silbö bajo demanda"
          available={true}
          image={ImageBank}
          isForImage={true}
          onClick={() => {
            dispatch(setProfile(SILBO_PROFILES.SILBO_IMAGES));
            navigate("/images", { replace: true });
          }}
          text="Silbö Images"
          textForBlur="1:00’"
        />
      </Col>
      <Col xs={4}>
        <CardApp
          auxiliaryText="Crea piezas de campaña en minutos"
          available={true}
          image={SilboAdCreator}
          isForImage={true}
          isForText={true}
          onClick={() => {
            window.open(
              "https://app.creatopy.com/team/1068996/project/2444501/templates",
              "_blank",
            );
          }}
          text="Plantillas Silbö"
          textForBlur="5:00’"
        />
      </Col>
    </Row>
  );
};
export default SilboHomeContent;
